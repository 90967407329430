define("lh-public-olo/models/delivery-address", ["exports", "@ember-data/model", "ember-cp-validations", "@ember/service", "@ember/object/computed"], function (_exports, _model, _emberCpValidations, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    addressLine1: {
      description: 'Address Line 1',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    addressLine2: (0, _emberCpValidations.validator)('length', {
      max: 255
    }),
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    state: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile(),
      description: 'State'
    }),
    zip: {
      description: 'Zip Code',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: (0, _computed.not)('model.ticket.orderType.requiresAddress').volatile()
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\d{5}(-\d{4})?$/,
        allowBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    ticket: (0, _service.inject)(),
    addressLine1: (0, _model.attr)('string'),
    addressLine2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    zip: (0, _model.attr)('string'),
    reset: function reset() {
      this.setProperties({
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zip: null
      });
    }
  });

  _exports.default = _default;
});