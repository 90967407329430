define("lh-public-olo/components/order-details/ticket-item", ["exports", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _object, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tbody',
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    surcharges: (0, _object.computed)('ticket.calculations', function () {
      if (this.get('ticket.calculations.items')) {
        var calcItem = this.get('ticket.calculations.items').findBy('guid', this.get('item.item.id'));
        return calcItem ? calcItem.surcharges.gross.fees.concat(calcItem.surcharges.net.fees) : undefined;
      }

      return undefined;
    }),
    actions: {
      removeItem: function removeItem(item) {
        this.removeItem(item);
      }
    }
  });

  _exports.default = _default;
});