define("lh-public-olo/utils/currency-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toCents = toCents;
  _exports.toDollars = toDollars;

  /**
   * Convert dollars to cents
   *
   * @param {number} dollars - amount in dollars
   * @returns {number} dollars amount in cents
   */
  function toCents(dollars) {
    if (isNaN(dollars)) {
      return 0;
    }

    return Math.round(parseFloat(dollars) * 100);
  }
  /**
   * Convert cents to dollar
   *
   * @param {number} cents - amount in cents
   * @returns {number} cents amount in dollars with two decimal precisions
   */


  function toDollars(cents) {
    return Math.round(cents) / 100;
  }
});