define("lh-public-olo/components/account/entry-view", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tab: 'SIGN-IN',
    actions: {
      close: function close() {
        this.sendAction('close');
      }
    }
  });

  _exports.default = _default;
});