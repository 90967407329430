define("lh-public-olo/routes/location/menu/order-settings", ["exports", "@ember/routing/route", "@ember/service", "rsvp"], function (_exports, _route, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    model: function model() {
      var location = this.modelFor('location');
      return (0, _rsvp.hash)({
        location: location,
        orderTypes: location.get('orderTypes'),
        customer: this.customerService.getCustomer()
      });
    }
  });

  _exports.default = _default;
});