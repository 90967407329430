define("lh-public-olo/templates/components/order-type-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "a45YoZD9",
    "block": "[[[10,0],[14,0,\"default text\"],[12],[1,\"Select Order Type\"],[13],[1,\"\\n\"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"menu\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"\\t\\t\"],[10,0],[15,\"data-value\",[29,[[30,1,[\"id\"]]]]],[14,0,\"item\"],[12],[1,\"\\n\\t\\t\\t\"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[41,[28,[37,4],[[30,1,[\"minOrderAmount\"]],[30,1,[\"maxOrderAmount\"]]],null],[[[1,\"\\t\\t\\t\\t\"],[10,\"i\"],[12],[1,\"(min: \"],[1,[28,[35,5],[[30,1,[\"minOrderAmount\"]]],null]],[1,\", max: \"],[1,[28,[35,5],[[30,1,[\"maxOrderAmount\"]]],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"minOrderAmount\"]],[[[1,\"\\t\\t\\t\\t\"],[10,\"i\"],[12],[1,\"(min: \"],[1,[28,[35,5],[[30,1,[\"minOrderAmount\"]]],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"maxOrderAmount\"]],[[[1,\"\\t\\t\\t\\t\"],[10,\"i\"],[12],[1,\"(max: \"],[1,[28,[35,5],[[30,1,[\"maxOrderAmount\"]]],null]],[1,\")\"],[13],[1,\"\\n\\t\\t\\t\"]],[]],null]],[]]]],[]]],[1,\"\\t\\t\"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[18,2,null],[1,\"\\n\"]],[\"orderType\",\"&default\"],false,[\"each\",\"-track-array\",\"orderTypes\",\"if\",\"and\",\"usd\",\"yield\"]]",
    "moduleName": "lh-public-olo/templates/components/order-type-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});