define("lh-public-olo/templates/components/delivery-address-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DqwWR0+E",
    "block": "[[[10,0],[15,0,[29,[\"field \",[52,[33,1,[\"orderType\",\"requiresAddress\"]],\"required\"]]]],[12],[1,\"\\n\\t\"],[10,\"label\"],[12],[1,\"Delivering to\"],[13],[1,\"\\n\"],[41,[33,2],[[[1,\"\\t\\t\"],[10,0],[14,0,\"ui dropdown\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"text\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[8,[39,3],null,[[\"@class\",\"@selected\",\"@action\",\"@onChange\"],[\"selection scrolling\",[28,[37,4],[[33,5,[\"selectedDeliveryAddress\",\"id\"]],\"NEW_ADDRESS\"],null],\"hide\",[28,[37,6],[[30,0],\"addressSelect\"],null]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[10,0],[15,0,[29,[\"text \",[52,[33,5,[\"selectedDeliveryAddress\",\"isNew\"]],\"new-address-text\"]]]],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"menu\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[33,9,[\"deliveryAddresses\"]]],null]],null],null,[[[1,\"\\t\\t\\t\\t\\t\"],[10,0],[14,0,\"item\"],[15,\"data-value\",[29,[[30,1,[\"id\"]]]]],[12],[1,[30,1,[\"addressLine1\"]]],[1,\", \"],[1,[30,1,[\"addressLine2\"]]],[1,[52,[30,1,[\"addressLine2\"]],\",\",\"\"]],[1,\" \"],[1,[30,1,[\"city\"]]],[1,\", \"],[1,[30,1,[\"state\"]]],[1,\", \"],[1,[30,1,[\"zip\"]]],[13],[1,\"\\n\"]],[1]],null],[1,\"\\t\\t\\t\\t\"],[10,0],[14,0,\"item new-address-text\"],[14,\"data-value\",\"NEW_ADDRESS\"],[12],[1,\"+ New Address\"],[13],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"]],[]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"deliveryAddress\"],false,[\"if\",\"ticket\",\"refreshDropdown\",\"ui-dropdown\",\"or\",\"customerService\",\"action\",\"each\",\"-track-array\",\"customer\"]]",
    "moduleName": "lh-public-olo/templates/components/delivery-address-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});