define("lh-public-olo/templates/components/checkout-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7gRp1uIp",
    "block": "[[[41,[28,[37,1],[[33,2,[\"items\",\"length\"]],[28,[37,3],[[33,2,[\"calculateLoading\"]]],null],[28,[37,3],[[33,2,[\"calculateError\"]]],null],[33,2,[\"menuSchedule\",\"isAvailable\"]]],null],[[[41,[33,2,[\"amountRangeViolation\"]],[[[1,\"\\t\\t\"],[10,\"button\"],[14,0,\"ui fluid primary button checkout\"],[12],[1,\"Checkout \"],[1,[28,[35,4],[[33,2,[\"total\"]]],null]],[13],[1,\"\\n\\t\\t\"],[8,[39,5],null,[[\"@popupTarget\",\"@position\",\"@showOnInsert\"],[[28,[37,6],[\"#\",[33,7],\" > .ui.button\"],null],[28,[37,6],[[52,[33,8,[\"isMobile\"]],\"top\",\"bottom\"],\" right\"],null],false]],[[\"default\"],[[[[1,[33,2,[\"amountRangeViolation\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[8,[39,9],[[24,0,\"ui fluid primary button checkout\"]],[[\"@route\",\"@tagName\"],[\"location.checkout\",\"button\"]],[[\"default\"],[[[[1,\"Checkout \"],[1,[28,[35,4],[[33,2,[\"total\"]]],null]]],[]]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"\\t\"],[10,\"button\"],[15,0,[29,[\"ui fluid primary button checkout \",[52,[33,2,[\"calculateLoading\"]],\"loading\",\"disabled\"]]]],[12],[1,\"Checkout\"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"and\",\"ticket\",\"not\",\"usd\",\"error-popup\",\"concat\",\"elementId\",\"media\",\"link-to\"]]",
    "moduleName": "lh-public-olo/templates/components/checkout-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});