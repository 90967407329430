define("lh-public-olo/templates/components/sold-out-warning-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qmDiZett",
    "block": "[[[8,[39,0],null,[[\"@autofocus\",\"@duration\",\"@onHide\"],[false,200,[28,[37,1],[[30,0],\"continue\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"i\"],[14,0,\"close icon\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"header\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,0,\"info-image\"],[12],[13],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"sold-out-heading\"],[12],[1,\"Sold Out\"],[13],[1,\"\\n        \"],[10,2],[14,0,\"sold-out-message\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[33,4,[\"length\"]],1],null],[[[1,\"            There is \"],[10,1],[14,0,\"item-count\"],[12],[1,[33,4,[\"length\"]]],[1,\" item\"],[13],[1,\" in your order which is sold out. This item will automatically be removed from your order.\\n\"]],[]],[[[1,\"            There are \"],[10,1],[14,0,\"item-count\"],[12],[1,[33,4,[\"length\"]]],[1,\" items\"],[13],[1,\" in your order which are sold out. These items will automatically be removed from your order.\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,4]],null]],null],null,[[[1,\"\\t\\t\\t\"],[10,0],[14,0,\"item-name\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"close icon\"],[12],[13],[1,\"\\n\\t\\t\\t\\t\"],[1,[30,1,[\"name\"]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"ui primary button fluid\"],[4,[38,1],[[30,0],\"continue\"],null],[12],[1,\"\\n            Continue\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"item\"],false,[\"ui-modal\",\"action\",\"if\",\"eq\",\"itemsOutOfStock\",\"each\",\"-track-array\"]]",
    "moduleName": "lh-public-olo/templates/components/sold-out-warning-view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});