define("lh-public-olo/templates/location/checkout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B4SkLMLP",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"kiosk-order-checkout\",\"component\",\"-outlet\"]]",
    "moduleName": "lh-public-olo/templates/location/checkout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});