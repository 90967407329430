define("lh-public-olo/services/feature-flag", ["exports", "@ember/service", "lh-public-olo/config/environment"], function (_exports, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FEATURE_FLAGS_AVAILABLE = ['skytab-online-ooc-get-location', 'skytab-online-ooc-get-menu', 'skytab-online-ooc-validate-distance', 'skytab-online-ooc-calculate-cart', 'skytab-online-ooc-preauthorize', 'skytab-online-ooc-place-order', 'sto-loyalty', 'sto-deferred-orders', 'sto-pay-by-gift-card', 'sto-item-images', 'sto-account-create', 'sto-customer-account-remember-information', 'sto-account-manage', 'sto-nested-mods', 'sto-quantity-on-hand', 'sto-order-confirmation-message'];

  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    featureFlagsEnabled: null,
    load: function load() {
      var _this = this;

      return this.ajax.request("".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/feature-flags")).then(function (enabled) {
        return _this.set('featureFlagsEnabled', enabled);
      }).catch(function () {
        return _this.set('featureFlagsEnabled', []);
      });
    },
    isEnabled: function isEnabled(featureFlag) {
      if (!FEATURE_FLAGS_AVAILABLE.includes(featureFlag)) {
        throw new Error("Invalid feature flag: ".concat(featureFlag));
      }

      if (!Array.isArray(this.featureFlagsEnabled)) {
        throw new Error('Feature flags is not loaded');
      }

      return this.featureFlagsEnabled.includes(featureFlag);
    }
  });

  _exports.default = _default;
});