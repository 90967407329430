define("lh-public-olo/controllers/location/checkout", ["exports", "@ember/service", "@ember/controller", "lh-public-olo/utils/clear-local-storage"], function (_exports, _service, _controller, _clearLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    customerService: (0, _service.inject)('customer'),
    actions: {
      submitResponse: function submitResponse() {
        var queryParams = {}; // if it was good, clear all the items out of localStorage

        if (this.get('ticket.submitResponse.status') === 'ok') {
          this.ticket.clearCart();
          (0, _clearLocalStorage.default)(this.get('ticket.locationRef'));
          this.ticket.setOrderSetting('discountCode', null);
          this.ticket.clearLoyaltyInfo();
          this.ticket.clearGiftCardDetails();
          this.set('customerService.selectedPaymentType', undefined);

          if (this.ticket.isOrderConfirmationMessageFeatureEnabled) {
            queryParams.orderRef = this.ticket.submitResponse.orderRef;
          }
        }

        this.transitionToRoute('location.confirmation', {
          queryParams: queryParams
        });
      },
      removeItem: function removeItem() {
        if (this.get('ticket.items.length') === 0) {
          this.transitionToRoute('location.menu');
        } else if (this.get('ticket.orderTypeGuid')) {
          this.ticket.calculate();
        }
      },
      showSoldOutWarningModal: function showSoldOutWarningModal() {
        this.transitionToRoute('location.checkout.sold-out-warning');
      }
    }
  });

  _exports.default = _default;
});