define("lh-public-olo/services/loyalty-member", ["exports", "@ember/service", "@ember/object", "libphonenumber", "ember-cp-validations"], function (_exports, _service, _object, _libphonenumber, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var isPhoneValid = function isPhoneValid(phoneNumber) {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber, 'US'));
    } catch (e) {
      return false;
    }
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    createMemberFirstName: [(0, _emberCpValidations.validator)('length', {
      max: 30,
      description: 'First Name'
    })],
    createMemberLastName: [(0, _emberCpValidations.validator)('length', {
      max: 30,
      description: 'Last Name'
    })]
  });

  var _default = _service.default.extend(Validations, {
    ticket: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isUsingContactDetails: true,
    customer: undefined,
    phoneNumber: (0, _object.computed)('customer.phone', 'isUsingContactDetails', 'createMemberPhoneNumber', function () {
      var phoneNumber = this.isUsingContactDetails ? this.get('customer.phone') : this.createMemberPhoneNumber;
      return isPhoneValid(phoneNumber) ? phoneNumber : null;
    }),
    lookupErrorResponse: undefined,
    lookupResponse: function lookupResponse(pointName) {
      if (this.memberRecord) {
        return "".concat(pointName || 'Point Balance', ": ").concat(this.get('memberRecord.pointsBalance'));
      }

      return undefined;
    },
    isLoading: false,
    memberRecord: undefined,
    memberNotFound: true,
    createErrorResponse: undefined,
    createMemberPhoneNumber: undefined,
    createMemberFirstName: undefined,
    createMemberLastName: undefined,
    createResponse: function createResponse(programName) {
      if (this.memberCreated) {
        return "You are now enrolled in ".concat(programName);
      }

      return undefined;
    },
    showCreate: false,
    memberCreated: false,
    load: function load() {
      var _this = this;

      var phoneNumber = this.get('memberRecord.phoneNumber');
      this.reset();

      if (phoneNumber) {
        this.store.queryRecord('loyalty/member', {
          phoneNumber: phoneNumber
        }).then(function (m) {
          _this.set('lookupErrorResponse', undefined);

          _this.set('memberRecord', m);

          _this.set('memberNotFound', false);
        }).catch(function (e) {
          if (e.errors[0].code === '404') {
            _this.set('memberNotFound', true);
          }

          _this.set('lookupErrorResponse', e.errors[0].friendlyError);

          _this.set('showCreate', true);

          if (_this.isUsingContactDetails) {
            _this.copyCustomerToCreateMember();
          }
        });
      }
    },
    searchMember: function searchMember() {
      var _this2 = this;

      if (!this.phoneNumber) {
        return;
      }

      if (!this.isUsingContactDetails) {
        this.set('isLoading', true);
      }

      this.reset();
      this.store.queryRecord('loyalty/member', {
        phoneNumber: this.phoneNumber
      }).then(function (m) {
        _this2.set('lookupErrorResponse', undefined);

        _this2.set('memberRecord', m);

        _this2.set('memberNotFound', false);
      }).catch(function (e) {
        if (e.errors[0].code === '404') {
          _this2.set('memberNotFound', true);
        }

        _this2.set('lookupErrorResponse', e.errors[0].friendlyError);

        _this2.set('showCreate', true);

        if (_this2.isUsingContactDetails) {
          _this2.copyCustomerToCreateMember();
        }
      }).finally(function () {
        return _this2.set('isLoading', false);
      });
    },
    reset: function reset() {
      this.set('memberCreated', false);
      this.set('memberNotFound', true);
      this.set('lookupErrorResponse', undefined);
      this.set('memberRecord', undefined);
      this.set('ticket.loyaltyDiscount', null);
      this.set('showCreate', false);
      this.ticket.calculate();
    },
    create: function create() {
      var _this3 = this;

      this.reset();
      var loyaltyMember = this.store.createRecord('loyalty/member', {
        phoneNumber: this.createMemberPhoneNumber,
        firstName: this.createMemberFirstName,
        lastName: this.createMemberLastName
      });
      loyaltyMember.save().then(function (m) {
        _this3.set('memberRecord', m);

        _this3.set('memberCreated', true);

        _this3.set('memberNotFound', false);

        _this3.set('createErrorResponse', undefined);

        _this3.set('lookupErrorResponse', undefined);
      }).catch(function (e) {
        _this3.set('memberRecord', undefined);

        _this3.set('createErrorResponse', e.errors[0].friendlyError);

        _this3.set('memberCreated', false);

        _this3.set('memberNotFound', true);

        _this3.set('showCreate', false);
      });
    },
    copyCustomerToCreateMember: function copyCustomerToCreateMember() {
      this.set('createMemberPhoneNumber', this.get('customer.phone'));
      this.set('createMemberFirstName', this.get('customer.firstName'));
      this.set('createMemberLastName', this.get('customer.lastName'));
    },
    useContactDetailsForPhone: function useContactDetailsForPhone() {
      this.copyCustomerToCreateMember();
      this.set('isUsingContactDetails', true);
      this.searchMember(this.get('customer.phone'));
    },
    clearPhone: function clearPhone() {
      this.reset();
      this.set('createMemberPhoneNumber', undefined);
      this.set('isUsingContactDetails', false);
    },
    customerPhoneChanged: function customerPhoneChanged(customer) {
      if (!this.customer) {
        this.set('customer', customer);
      }

      if (this.isUsingContactDetails && this.createMemberPhoneNumber !== customer.get('phone')) {
        this.searchMember();
      }
    }
  });

  _exports.default = _default;
});