define("lh-public-olo/components/order-info", ["exports", "@ember/component", "@ember/service", "lh-public-olo/utils/clear-local-storage"], function (_exports, _component, _service, _clearLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['order-info'],
    ticket: (0, _service.inject)(),
    router: (0, _service.inject)(),
    actions: {
      updateOrderType: function updateOrderType(orderType) {
        this.set('ticket.orderTypeLabel', orderType);
      },
      startOver: function startOver() {
        (0, _clearLocalStorage.default)(this.get('ticket.locationRef'));
        window.location.replace("/".concat(this.get('ticket.locationRef')));
      }
    }
  });

  _exports.default = _default;
});