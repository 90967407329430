define("lh-public-olo/templates/components/quantity-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pq1fe6h/",
    "block": "[[[11,\"img\"],[16,0,[29,[[52,[28,[37,1],[[33,2],1],null],\"disabled\"],\" minus icon\"]]],[24,\"src\",\"/img/icons/minus-circle.svg\"],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"subtract\"],null],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"value\"],[12],[1,[34,2]],[13],[1,\"\\n\"],[11,\"img\"],[24,0,\"plus icon\"],[24,\"src\",\"/img/icons/plus-circle.svg\"],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"add\"],null],[12],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"value\",\"action\"]]",
    "moduleName": "lh-public-olo/templates/components/quantity-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});