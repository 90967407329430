define("lh-public-olo/models/loyalty/member", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('phone-number')]
  });

  var _default = _model.default.extend(Validations, {
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    pointsBalance: (0, _model.attr)('number'),
    phoneNumber: (0, _model.attr)('string')
  });

  _exports.default = _default;
});