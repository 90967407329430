define("lh-public-olo/templates/components/back-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0YqVgXW9",
    "block": "[[[10,0],[14,0,\"back-button-container\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"\\t\\t\"],[8,[39,2],null,[[\"@route\"],[[99,1,[\"@route\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"/img/arrow_back.png\"],[14,\"alt\",\"Back\"],[14,0,\"back-image\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,1],[14,0,\"back-text\"],[12],[1,\"Back\"],[13],[1,\"\\n\\t\\t\"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[33,3],[[[1,\"\\t\\t\"],[11,3],[24,6,\"#\"],[4,[38,4],[[30,0],[33,3]],null],[12],[1,\"\\n\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"/img/arrow_back.png\"],[14,\"alt\",\"Back\"],[14,0,\"back-image\"],[12],[13],[1,\"\\n\\t\\t\\t\"],[10,1],[14,0,\"back-text\"],[12],[1,\"Back\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]],null]],[]]],[13],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"link\",\"link-to\",\"onClick\",\"action\",\"yield\"]]",
    "moduleName": "lh-public-olo/templates/components/back-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});