define("lh-public-olo/models/menu-schedule", ["exports", "@ember-data/model", "@ember/object", "@ember/service"], function (_exports, _model, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ticket: (0, _service.inject)(),
    name: (0, _model.attr)('string'),
    hours: (0, _model.hasMany)('menu-schedule-hour'),
    duringOffHours: (0, _model.attr)('boolean'),
    isAvailable: (0, _object.computed)('hours.[]', 'hours.@each.isAvailable', 'ticket.dineIn', function () {
      return this.get('ticket.dineIn') || this.hours.any(function (hour) {
        return hour.get('isAvailable');
      });
    })
  });

  _exports.default = _default;
});