define("lh-public-olo/routes/application", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      dineIn: {
        refreshModel: true
      },
      tableNotes: {
        refreshModel: true
      }
    },
    ticket: (0, _service.inject)(),
    featureFlag: (0, _service.inject)(),
    model: function model(_ref) {
      var dineIn = _ref.dineIn,
          tableNotes = _ref.tableNotes;
      this.ticket.setProperties({
        dineIn: !!dineIn,
        tableNotes: tableNotes
      });
      return this.featureFlag.load();
    }
  });

  _exports.default = _default;
});