define("lh-public-olo/templates/location/idle-screen", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HcOpPv2X",
    "block": "[[[10,0],[14,0,\"idle-body\"],[12],[1,\"\\n\"],[41,[30,0,[\"orderStart\"]],[[[1,\"\\t\\t\"],[10,0],[14,0,\"image-logo\"],[12],[1,\"\\n\"],[41,[33,1,[\"logoUrl\"]],[[[1,\"\\t\\t\\t\\t\"],[10,\"img\"],[15,\"src\",[29,[[33,1,[\"logoUrl\"]]]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[10,\"h1\"],[14,0,\"location-name\"],[12],[1,[33,1,[\"name\"]]],[13],[1,\"\\n\\t\\t\"],[10,2],[14,0,\"welcome-message\"],[12],[1,\"Taste for yourself what everyone is raving about!\"],[13],[1,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"ui button primary start-order-button\"],[4,[38,2],[[30,0],\"startOrder\"],null],[12],[1,\"START ORDER\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\"],[10,\"h1\"],[14,0,\"order-type-title\"],[12],[1,\"Welcome to \"],[1,[33,1,[\"name\"]]],[13],[1,\"\\n\\t\\t\"],[10,2],[14,0,\"order-type\"],[12],[1,\"How would you like your order?\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"order-settings-main\"],[12],[1,\"\\n\\t\\t\\t\"],[11,\"button\"],[24,0,\"ui button order-type-button\"],[4,[38,2],[[30,0],\"orderTypeSelect\",\"DINE_IN\"],null],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"/img/logo-for-here.svg\"],[12],[13],[1,\"\\n\\t\\t\\t\\tFor Here\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[11,\"button\"],[24,0,\"ui button order-type-button\"],[4,[38,2],[[30,0],\"orderTypeSelect\",\"TAKEOUT\"],null],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"/img/logo-to-go.svg\"],[12],[13],[1,\"\\n\\t\\t\\t\\tTo Go\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"idle-logo-container\"],[12],[1,\"\\n\\t\"],[10,\"img\"],[14,\"src\",\"/img/logo-long.png\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"model\",\"action\"]]",
    "moduleName": "lh-public-olo/templates/location/idle-screen.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});