define("lh-public-olo/controllers/location/idle-screen", ["exports", "@ember/controller", "@ember/service", "lh-public-olo/utils/clear-local-storage"], function (_exports, _controller, _service, _clearLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    ticket: (0, _service.inject)(),
    router: (0, _service.inject)(),
    orderStart: true,
    actions: {
      startOrder: function startOrder() {
        this.set('orderStart', false);
      },
      orderTypeSelect: function orderTypeSelect(orderTypeGuid) {
        var _this = this;

        (0, _clearLocalStorage.default)(this.get('ticket.locationRef'));
        this.set('ticket.orderTypeLabel', orderTypeGuid);
        this.router.replaceWith('location.menu', {
          queryParams: {
            dineIn: true
          }
        }).then(function () {
          _this.set('orderStart', true);
        });
      }
    }
  });

  _exports.default = _default;
});