define("lh-public-olo/helpers/modifier-display-label", ["exports", "@ember/component/helper", "@ember/utils", "lh-public-olo/helpers/usd"], function (_exports, _helper, _utils, _usd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modifierDisplayLabel = modifierDisplayLabel;
  _exports.default = void 0;

  function modifierDisplayLabel(params
  /*, hash*/
  ) {
    var modifier = params[0];
    var name = modifier.get('name');
    var price = modifier.get('price');

    if ((0, _utils.isEmpty)(modifier) || (0, _utils.isEmpty)(name)) {
      return 'n/a';
    }

    if ((0, _utils.isEmpty)(price) || !price) {
      return name;
    }

    return "".concat(name, " (").concat((0, _usd.usd)([price]), ")");
  }

  var _default = (0, _helper.helper)(modifierDisplayLabel);

  _exports.default = _default;
});