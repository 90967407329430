define("lh-public-olo/controllers/location/checkout/account/entry", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      close: function close() {
        this.transitionToRoute('location.checkout');
      }
    }
  });

  _exports.default = _default;
});