define("lh-public-olo/templates/components/menu-column", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HHX6KBP9",
    "block": "[[[18,3,null],[1,\"\\n\\n\"],[41,[33,2,[\"calculateErrors\"]],[[[1,\"\\t\"],[8,[39,3],null,[[\"@errors\"],[[33,2,[\"calculateErrors\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,2,[\"itemsRemoved\"]],[[[1,\"\\t\"],[8,[39,4],null,[[\"@number\"],[[33,2,[\"itemsRemoved\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"\\t\"],[10,\"h2\"],[15,1,[29,[[30,1,[\"id\"]]]]],[14,0,\"ui header row department-header\"],[12],[1,\"\\n\\t\\t\"],[1,[30,1,[\"name\"]]],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"sub header\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[10,0],[14,0,\"ui department top aligned grid\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"\\t\\t\\t\"],[8,[39,8],null,[[\"@class\",\"@item\"],[\"eight wide computer eight wide tablet sixteen wide mobile item column\",[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\t\"],[13],[1,\"\\n\"]],[1]],null]],[\"category\",\"item\",\"&default\"],false,[\"yield\",\"if\",\"ticket\",\"calculation-errors\",\"items-removed-warning\",\"each\",\"-track-array\",\"categories\",\"menu-item\"]]",
    "moduleName": "lh-public-olo/templates/components/menu-column.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});