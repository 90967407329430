define("lh-public-olo/components/account/reset-password", ["exports", "@ember/component", "@ember/service", "rsvp", "ember-cp-validations", "lh-public-olo/utils/validation-check", "lh-public-olo/config/environment"], function (_exports, _component, _service, _rsvp, _emberCpValidations, _validationCheck, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: 'Email Address',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        minTldLength: 2
      })]
    }
  });

  var _default = _component.default.extend(Validations, {
    ticket: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    classNames: ['user-account-view', 'reset-password'],
    isEmailSent: false,
    isLoading: false,
    showErrors: false,
    resetPassword: function resetPassword() {
      var _this = this;

      this.set('isLoading', true);
      var url = "".concat(_environment.default.oocHost, "/online-ordering/mars/api/v2/customer/forgot-password");
      var email = this.email;
      var passwordResetLinkTemplate = "".concat(window.location.origin, "/").concat(this.get('ticket.locationRef'), "/account/update-password?token=${invitationGuid}");
      return this.ajax.post(url, {
        data: {
          email: email,
          passwordResetLinkTemplate: passwordResetLinkTemplate
        }
      }).then(function () {
        _this.set('isEmailSent', true);
      }).catch(function (e) {
        var resetPasswordError = 'Something went wrong, please try again';

        if (e.payload && e.payload.errors && e.payload.errors.length && e.payload.errors[0].friendlyError) {
          resetPasswordError = e.payload.errors[0].friendlyError;
        }

        return (0, _rsvp.reject)({
          resetPasswordError: resetPasswordError
        });
      });
    },
    actions: {
      resetPassword: function resetPassword() {
        var _this2 = this;

        if (this.isLoading) {
          return;
        }

        this.setProperties({
          showErrors: false,
          resetPasswordError: undefined
        });
        this.validate().then(_validationCheck.default).then(function () {
          return _this2.resetPassword();
        }).catch(function (_ref) {
          var validations = _ref.validations,
              resetPasswordError = _ref.resetPasswordError;

          _this2.setProperties({
            showErrors: validations,
            resetPasswordError: resetPasswordError
          });
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      close: function close() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});