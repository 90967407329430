define("lh-public-olo/components/quantity-select", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['quantity-select'],
    actions: {
      subtract: function subtract() {
        if (this.value > 1) {
          this.decrementProperty('value');
        }
      },
      add: function add() {
        this.incrementProperty('value');
      }
    }
  });

  _exports.default = _default;
});