define("lh-public-olo/components/eta-time", ["exports", "@ember/component", "@ember/service", "lh-public-olo/utils/time-util", "@ember/runloop", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _timeUtil, _runloop, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'span',
    ticket: (0, _service.inject)(),
    etaTimer: undefined,
    etaIntervalTimer: undefined,
    date: undefined,
    orderType: (0, _computed.oneWay)('ticket.orderType'),
    orderEta: (0, _object.computed)('date', 'orderType.id', 'ticket.distanceLeadTimeInMinutes', function () {
      return (0, _timeUtil.getTimeAsString)((0, _timeUtil.addMinutes)(this.date, this.ticket.getLeadTimeInMinutes(this.orderType)));
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var date = this.date;
      this.set('date', (0, _timeUtil.getAdjustedTime)(this.get('ticket.timeZoneOffset'), date ? new Date(date) : undefined));

      if (!date) {
        this.initTimer();
      }
    },
    willDestroyElement: function willDestroyElement() {
      (0, _runloop.cancel)(this.etaTimer);
      clearInterval(this.etaIntervalTimer);

      this._super.apply(this, arguments);
    },
    initTimer: function initTimer() {
      var secondsRemaining = (60 - new Date().getSeconds()) * 1000;
      this.set('etaTimer', (0, _runloop.later)(this, function () {
        var _this = this;

        this.set('date', (0, _timeUtil.getAdjustedTime)(this.get('ticket.timeZoneOffset')));
        this.set('etaIntervalTimer', setInterval((0, _runloop.bind)(this, function () {
          _this.set('date', (0, _timeUtil.getAdjustedTime)(_this.get('ticket.timeZoneOffset')));
        }), 60000));
      }, secondsRemaining));
    }
  });

  _exports.default = _default;
});