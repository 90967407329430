define("lh-public-olo/templates/components/order-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cPf1vTgf",
    "block": "[[[10,0],[14,0,\"order-info-body\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"order-type-container\"],[12],[1,\"\\n\\t\\t\"],[11,\"button\"],[16,0,[29,[\"ui button order-button \",[52,[28,[37,1],[[33,2,[\"orderTypeLabel\"]],\"DINE_IN\"],null],\"selected\"]]]],[4,[38,3],[[30,0],\"updateOrderType\",\"DINE_IN\"],null],[12],[1,\"For Here\"],[13],[1,\"\\n\\t\\t\"],[11,\"button\"],[16,0,[29,[\"ui button order-button \",[52,[28,[37,1],[[33,2,[\"orderTypeLabel\"]],\"TAKEOUT\"],null],\"selected\"]]]],[4,[38,3],[[30,0],\"updateOrderType\",\"TAKEOUT\"],null],[12],[1,\"To Go\"],[13],[1,\"\\n\\t\"],[13],[1,\"\\n\\t\"],[11,\"button\"],[16,0,[29,[\"ui button start-over \",[52,[28,[37,4],[[33,2,[\"items\",\"length\"]],[28,[37,5],[[33,2,[\"calculateLoading\"]]],null],[28,[37,5],[[33,2,[\"calculateError\"]]],null],[33,2,[\"menuSchedule\",\"isAvailable\"]]],null],\"items-available\"]]]],[4,[38,3],[[30,0],\"startOver\"],null],[12],[10,\"img\"],[14,\"src\",\"/img/refresh.svg\"],[12],[13],[1,\" Start Over\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"ticket\",\"action\",\"and\",\"not\"]]",
    "moduleName": "lh-public-olo/templates/components/order-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});