define("lh-public-olo/components/app-header", ["exports", "@ember/component", "@ember/object", "@ember/service", "jquery"], function (_exports, _component, _object, _service, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'header',
    ticket: (0, _service.inject)(),
    media: (0, _service.inject)(),
    numberOfItems: (0, _object.computed)('ticket.items.[]', 'ticket.items.@each.quantity', function () {
      return this.get('ticket.items').reduce(function (a, b) {
        return a + Number(b.get('quantity'));
      }, 0);
    }),
    reset: function reset() {
      (0, _jquery.default)('.order.column').removeClass('open');
      (0, _jquery.default)('main > .column:first-child').dimmer({
        onHide: function onHide() {}
      }).dimmer('hide');
    },
    // REEEEE OBSERVERS REEEEE
    screenChange: (0, _object.observer)('media.{isTablet,isMobile,isDesktop}', function () {
      // when the screen size changes, just hide everything
      this.reset();
    }),
    actions: {
      openDetails: function openDetails() {
        var _this = this;

        (0, _jquery.default)('.order.column').toggleClass('open');

        if (this.get('media.isTablet')) {
          (0, _jquery.default)('main > .column:first-child').dimmer({
            onHide: function onHide() {
              return _this.reset();
            }
          }).dimmer('toggle');
        }
      }
    }
  });

  _exports.default = _default;
});