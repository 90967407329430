define("lh-public-olo/components/kiosk-order-checkout", ["exports", "@ember/component", "libphonenumber", "@ember/service", "rsvp", "lh-public-olo/config/environment", "lh-public-olo/utils/validation-check"], function (_exports, _component, _libphonenumber, _service, _rsvp, _environment, _validationCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PNF = _libphonenumber.default.PhoneNumberFormat;

  var _default = _component.default.extend({
    ticket: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    phoneNumberValidated: false,
    step: 'info',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Shift4Kiosk.onSaleSuccess = function (successResult) {
        _this.placeKioskOrder(successResult);
      };

      Shift4Kiosk.onSaleError = function (errorResult) {
        _this.set('step', 'payment_failed');
      };
    },
    requestSale: function requestSale(saleAmount, tipAmount) {
      Shift4Kiosk.sale(JSON.stringify({
        isStaging: true,
        accessToken: '',
        ticketId: '',
        invoiceNumber: '',
        saleAmountInPennies: saleAmount,
        tipAmountInPennies: tipAmount,
        taxAmountInPennies: 0,
        customerCode: '',
        allowPartialAuth: true,
        forceDuplicate: true
      }));
    },
    //TODO: We will be using a dymmy customer details for now. This will be replaced in MVP. 
    getKioskCustomer: function getKioskCustomer() {
      return {
        firstName: 'Kiosk',
        lastName: 'Order',
        email: 'kiosk@shift4.com',
        phone: this.get('phone')
      };
    },
    placeKioskOrder: function placeKioskOrder(saleResponse) {
      var _this2 = this;

      this.set('step', 'payment_loading');
      this.validateOrder().then(function () {
        return _this2.submitOrder(saleResponse);
      }).catch(function () {
        _this2.set('step', 'payment_failed');
      });
    },
    submitOrder: function submitOrder(saleResponse) {
      var _this3 = this;

      if (this.get('ticket.calculateErrors')) {
        return;
      }

      var data = {
        cart: this.ticket.getCart(),
        orderTypeRef: this.get('ticket.orderTypeLabel'),
        customer: this.getKioskCustomer(),
        tenderRef: this.get('ticket.tenderTypeGuid'),
        grandTotal: this.get('ticket.totalWithTip'),
        invoiceNumbers: [saleResponse.invoiceNumber.toString()],
        tableNotes: 'Kiosk 1'
      };
      var url = "".concat(_environment.default.host, "/api/v3/public/kiosk/").concat(this.get('ticket.locationRef'), "/order");
      return this.ajax.raw(url, {
        type: 'POST',
        data: data
      }).then(function (_ref) {
        var response = _ref.response;
        return _this3.set('ticket.submitResponse', response);
      }).catch(function (e) {
        _this3.set('ticket.submitResponse', e && e.payload ? e.payload : {
          status: 'error'
        });
      }).finally(function () {
        if (_this3.get('ticket.submitResponse.status') === 'ok') {
          _this3.set('step', 'payment_complete');

          return;
        }

        _this3.set('step', 'payment_failed');
      });
    },
    validateOrder: function validateOrder() {
      var _this4 = this;

      if (!this.get('ticket.amountRangeViolation') && !this.get('ticket.calculateErrors')) {
        return this.ticket.calculate(true).then(function () {
          return _this4.ticket.validate();
        }).then(_validationCheck.default).catch(function (e) {
          return (0, _rsvp.reject)();
        });
      }

      return (0, _rsvp.reject)();
    },
    actions: {
      onPhoneBlur: function onPhoneBlur() {
        try {
          var phoneNumber = phoneUtil.parse(this.get('phone'), 'US');

          if (phoneUtil.isValidNumber(phoneNumber)) {
            this.set('phone', phoneUtil.format(phoneNumber, PNF.INTERNATIONAL));
            this.set('phoneNumberValidated', true);
            return;
          }

          this.set('phoneNumberValidated', false);
        } catch (e) {
          this.set('phoneNumberValidated', false);
        }
      },
      goToPayment: function goToPayment(paymentType) {
        if (paymentType === 'NON_CASH') {
          this.set('step', 'credit_payment');
          this.requestSale(this.get('ticket.total'), this.get('ticket.tipAmount'));
          return;
        }

        this.set('step', 'cash_payment');
      },
      startNewOrder: function startNewOrder() {
        window.location.replace("/".concat(this.get('ticket.locationRef')));
      }
    }
  });

  _exports.default = _default;
});