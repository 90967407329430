define("lh-public-olo/routes/location/menu", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/object"], function (_exports, _service, _route, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ticket: (0, _service.inject)(),
    // I don't absolutely love this solution, BUT it is very similar to how query params
    // work under the hood in ember directly, and it allows us to have very similar functionality
    // without actually needing to store state in the URL, so I think it solves a lot of our issues
    // and centralizes the code smell to this one specific observer
    orderSettings: (0, _object.observer)('ticket.{orderTypeGuid,menuScheduleRef,expectedTimeUtc}', function () {
      if (this.get('controller.model')) {
        return this.refresh();
      }
    }),
    beforeModel: function beforeModel(transition) {
      if (transition.targetName.includes('account.entry')) {
        return this.replaceWith(transition.targetName);
      }

      if (!this.get('ticket.orderTypeGuid')) {
        return this.replaceWith('location.menu.order-settings');
      }
    },
    model: function model() {
      var _this = this;

      var location = this.modelFor('location');
      return this.getSchedules().then(function (menuSchedules) {
        // set a default menu schedule if one isn't set already
        if (!_this.get('ticket.menuScheduleRef')) {
          _this.ticket.setProperties({
            menuSchedule: menuSchedules.get('firstObject'),
            menuScheduleRef: menuSchedules.get('firstObject.id')
          });

          _this.ticket.setOrderSetting('menuScheduleRef', menuSchedules.get('firstObject.id'));
        }

        var schedule = _this.get('ticket.menuScheduleRef');

        return (0, _rsvp.hash)({
          categories: _this.store.query('category', {
            schedule: schedule
          }),
          items: _this.store.query('item', {
            schedule: schedule
          })
        }).then(function (result) {
          return (0, _rsvp.hash)({
            location: location,
            menuSchedules: menuSchedules,
            // filter out categories without any items
            categories: result.categories.filter(function (category) {
              return category.get('items.length') !== 0;
            })
          });
        });
      });
    },
    afterModel: function afterModel() {
      this.ticket.calculate();
    },
    getSchedules: function getSchedules() {
      var _this2 = this;

      var location = this.modelFor('location');

      if (!this.get('ticket.isDeferredFeatureEnabled') || !this.get('ticket.orderTypeGuid') || this.get('ticket.orderTypeGuid') === 'DINE_IN') {
        return (0, _rsvp.resolve)(location.get('menuSchedules'));
      }

      if (!this.get('ticket.isDeferred')) {
        return this.ticket.getAvailableTimeSlots().then(function (timeSlot) {
          if (!timeSlot.asapTimeSlot || !timeSlot.asapTimeSlot.isAvailable) {
            _this2.set('ticket.storeSettingsChangedError', 'Your chosen order time is no longer available.');

            _this2.replaceWith('location.menu.order-settings');
          }

          return (0, _rsvp.resolve)(location.get('menuSchedules'));
        });
      }

      return this.ticket.getOrderTime().then(function (orderTimeUtc) {
        return _this2.store.query('available-menu-schedule', {
          orderType: _this2.get('ticket.orderTypeGuid'),
          time: orderTimeUtc
        });
      }).then(function (menuSchedules) {
        if (menuSchedules.get('length') === 0) {
          return (0, _rsvp.reject)('There are no menu schedules available for your selected time.');
        }

        return (0, _rsvp.resolve)(menuSchedules);
      }).catch(function (message) {
        _this2.set('ticket.storeSettingsChangedError', message);

        _this2.replaceWith('location.menu.order-settings');

        return (0, _rsvp.resolve)(location.get('menuSchedules'));
      });
    }
  });

  _exports.default = _default;
});